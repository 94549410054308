import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Tokens = () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("name");
  const [chunkNumber, setChunkNumber] = useState(1); // Track which chunk to load
  const [hasMoreTokens, setHasMoreTokens] = useState(true); // Track if more tokens are available

  // Function to load tokens based on the current chunk and search query
  const loadTokens = async (query, chunk) => {
    if (!hasMoreTokens) return; // Stop if no more tokens are available
    setLoading(true);

    try {
      const response = await fetch(`/tokens/tokens_chunk_${chunk}.json`);
      if (!response.ok) {
        setHasMoreTokens(false); // No more chunks found
        return;
      }
      const data = await response.json();

      // Filter tokens based on search query (before setting the state)
      const filteredTokens = data.filter(
        (token) =>
          token.name.toLowerCase().includes(query) ||
          token.symbol.toLowerCase().includes(query) ||
          token.address.toLowerCase().includes(query)
      );

      setTokens((prevTokens) => [...prevTokens, ...filteredTokens]);
      setChunkNumber(chunk + 1); // Increment chunk number for next fetch
    } catch (error) {
      console.error("Error loading tokens:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      // Reset state when a new search query is entered
      setTokens([]);
      setChunkNumber(1);
      setHasMoreTokens(true);
      loadTokens(searchQuery, 1); // Start from the first chunk
    }
  }, [searchQuery]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleSort = (event) => {
    setSortOption(event.target.value);
  };

  const loadMoreTokens = () => {
    if (hasMoreTokens && !loading) {
      loadTokens(searchQuery, chunkNumber);
    }
  };

  const sortedAndFilteredTokens = tokens
    .sort((a, b) => {
      if (sortOption === "name") {
        return a.name.localeCompare(b.name);
      } else if (sortOption === "symbol") {
        return a.symbol.localeCompare(b.symbol);
      }
      return 0;
    });

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Tokens</h1>

      {/* Search and Sorting */}
      <div style={styles.controls}>
        <input
          type="text"
          placeholder="Search by name, symbol, or mint address..."
          style={styles.searchBar}
          value={searchQuery}
          onChange={handleSearch}
        />
        <select style={styles.sortDropdown} value={sortOption} onChange={handleSort}>
          <option value="name">Sort by Name</option>
          <option value="symbol">Sort by Symbol</option>
        </select>
      </div>

      {/* Tokens Grid */}
      <div style={styles.grid}>
        {sortedAndFilteredTokens.map((token) => (
          <div key={token.address} style={styles.card}>
            <img
              src={token.logoURI}
              alt={token.name}
              style={styles.tokenImage}
            />
            <h3>{token.name}</h3>
            <p>{token.symbol}</p>
            <p style={styles.mintAddress}>{token.address}</p>
            <Link to={`/trade/${token.address}`}>
              <button style={styles.tradeButton}>Trade</button>
            </Link>
          </div>
        ))}
      </div>

      {/* Load More Button */}
      {hasMoreTokens && (
        <button onClick={loadMoreTokens} style={styles.loadMoreButton}>
          {loading ? "Loading..." : "Load More"}
        </button>
      )}

      {/* Loading State */}
      {loading && !hasMoreTokens && (
        <p style={styles.loadingText}>No more tokens available</p>
      )}
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: "20px",
    fontFamily: "'Arial', sans-serif",
    maxWidth: "1200px",
    margin: "auto",
  },
  heading: {
    textAlign: "center",
    marginBottom: "20px",
    color: "#333",
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  searchBar: {
    width: "70%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
  },
  sortDropdown: {
    width: "25%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gap: "20px",
  },
  card: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  tokenImage: {
    width: "80px",
    height: "80px",
    marginBottom: "10px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  tradeButton: {
    padding: "8px 16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  },
  loadMoreButton: {
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    display: "block",
    margin: "20px auto",
  },
  mintAddress: {
    fontSize: "12px",
    color: "#555",
    wordWrap: "break-word",
  },
  loadingText: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "16px",
    color: "#555",
  },
};

export default Tokens;
