import React, { useState, useEffect } from "react";

const Wallet = () => {
  const [walletData, setWalletData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [withdrawError, setWithdrawError] = useState(null);
  const [withdrawSuccess, setWithdrawSuccess] = useState(null);

  // Utility function to get cookie value
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    return match ? match[2] : null;
  };

  useEffect(() => {
    // Fetch wallet data
    const fetchWalletData = async () => {
      const uid = getCookie("token"); // Get UID from 'token' cookie
      if (!uid) {
        setError("UID not found in cookies.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://api.dex.chipa.tech/balance/${uid}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch wallet data");
        }

        const data = await response.json();
        setWalletData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchWalletData();
  }, []);

  const handleWithdraw = async () => {
    // Validation
    if (!withdrawAddress || !withdrawAmount) {
      setWithdrawError("Please provide both address and amount.");
      return;
    }

    if (isNaN(withdrawAmount) || withdrawAmount <= 0) {
      setWithdrawError("Please enter a valid withdrawal amount.");
      return;
    }

    const uid = getCookie("token"); // Get UID from 'token' cookie
    if (!uid) {
      setWithdrawError("UID not found in cookies.");
      return;
    }

    const amount = parseFloat(withdrawAmount);

    try {
      alert("Withdrawing...")
      const response = await fetch("https://api.dex.chipa.tech/withdraw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: uid,
          destinationAddress: withdrawAddress,
          amount: amount,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to withdraw funds");
      }

      const data = await response.json();
      setWithdrawSuccess(data.message); // Assuming the response contains a success message
      setWithdrawError(null); // Clear any previous errors
      // Optionally refetch wallet data to update balance after withdrawal
      await fetchWalletData();
    } catch (err) {
      setWithdrawError(err.message);
      setWithdrawSuccess(null);
    }
  };

  const fetchWalletData = async () => {
    const uid = getCookie("token"); // Get UID from 'token' cookie
    if (!uid) {
      setError("UID not found in cookies.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `https://api.dex.chipa.tech/balance/${uid}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch wallet data");
      }

      const data = await response.json();
      setWalletData(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) return <p className="loading">Loading...</p>;
  if (error) return <p className="error">Error: {error}</p>;

  const { publicKey, balance, tokens } = walletData;

  return (
    <div className="wallet-container">
      <h1 className="wallet-title">Wallet</h1>
      <div className="wallet-info">
        <div className="wallet-address">
          <h3>Solana Address</h3>
          <p>{publicKey}</p>
        </div>
        <div className="wallet-balance">
          <h3>Balance</h3>
          <p>{balance} SOL</p>
        </div>
      </div>
      <div className="wallet-actions">
        <button className="btn-deposit">
          Deposit
        </button>
        <button className="btn-withdraw" onClick={handleWithdraw}>
          Withdraw
        </button>
      </div>

      {/* Withdraw Form */}
      <div className="withdraw-form">
        <h3>Withdraw</h3>
        <div>
          <label>Withdraw Address</label>
          <input
            type="text"
            value={withdrawAddress}
            onChange={(e) => setWithdrawAddress(e.target.value)}
            placeholder="Enter withdrawal address"
          />
        </div>
        <div>
          <label>Amount</label>
          <input
            type="number"
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
            placeholder="Enter amount to withdraw"
          />
        </div>
        {withdrawError && <p className="error">{withdrawError}</p>}
        {withdrawSuccess && <p className="success">{withdrawSuccess}</p>}
        <button className="btn-withdraw" onClick={handleWithdraw}>
          Confirm Withdrawal
        </button>
      </div>

      {tokens && tokens.length > 0 && (
        <div className="wallet-tokens">
          <h3>Token Balances</h3>
          <ul>
            {tokens.map((token, index) => (
              <li key={index}>
                <span>Token: {token.tokenAddress}</span>
                <span>Amount: {token.amount}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Wallet;
