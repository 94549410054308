import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import TradingViewWidget from "../tradingview";
import { FaBitcoin, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import axios from 'axios'; // Import Axios for HTTP requests

const DashboardDark = () => {
  const [buyAmount, setBuyAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [walletData, setWalletData] = useState(null); // Store wallet data
  const [loading, setLoading] = useState(true); // Handle loading state
  const [error, setError] = useState(null); // Handle error state
  const { cryptoPair } = useParams();
  const [transactionLink, setTransactionLink] = useState(null); // Store the transaction link response

  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    return match ? match[2] : null;
  };

  const uid = getCookie("token"); // Get UID from 'token' cookie
  useEffect(() => {
    if (!uid) {
      setError("UID not found in cookies.");
      setLoading(false);
    } else {
      const fetchWalletData = async () => {
        try {
          const response = await axios.get(`https://api.dex.chipa.tech/balance/${uid}`);
          setWalletData(response.data || null);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchWalletData();
    }
  }, [uid]);

  const handleBuy = async () => {
    alert("Buying...")
    try {
      const data = {
        inputAmount: buyAmount,
        slippageBps: 50,
        inputMint: 'So11111111111111111111111111111111111111112',
        outputMint: cryptoPair,
        uid: uid,
      };

      const response = await axios.post('https://api.dex.chipa.tech/swap', data);

      if (response.data.transactionLink) {
        setTransactionLink(response.data.transactionLink);
        alert('Swap successful! Transaction link: ' + response.data.transactionLink);
      }
    } catch (error) {
      console.error('Error during swap:', error);
      alert('Error during swap: ' + error.response?.data?.message || error.message);
    }
  };

  const handleSell = async () => {
    alert("Selling...")
    try {
      const data = {
        inputAmount: sellAmount,
        slippageBps: 50,
        inputMint: cryptoPair,
        outputMint: 'So11111111111111111111111111111111111111112',
        uid: uid,
      };

      const response = await axios.post('https://api.dex.chipa.tech/swap', data);

      if (response.data.transactionLink) {
        setTransactionLink(response.data.transactionLink);
        alert('Swap successful! Transaction link: ' + response.data.transactionLink);
      }
    } catch (error) {
      console.error('Error during swap:', error);
      alert('Error during swap: ' + error.response?.data?.message || error.message);
    }
  };

  // Media Queries for responsive design
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <div className="container-fluid p-4" style={{ backgroundColor: '#2c2c2c', borderRadius: '8px' }}>
      <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>

        {/* Chart Section */}
        <div className={`col-${isMobile ? '12' : 'xl-8'}`} style={{ flex: 1 }}>
          <div className="card shadow-sm" style={{ height: isMobile ? "400px" : "800px" }}>
            <div className="card-body p-0" style={{ height: "100%" }}>
              <style>
                {`
                  #dexscreener-embed {
                    position: relative;
                    width: 100%;
                    padding-bottom: 125%;
                  }
                  @media (min-width: 1400px) {
                    #dexscreener-embed {
                      padding-bottom: 65%;
                    }
                  }
                  #dexscreener-embed iframe {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border: 0;
                  }
                `}
              </style>
              <div id="dexscreener-embed">
                <iframe
                  src={`https://dexscreener.com/solana/${cryptoPair}?embed=1&loadChartSettings=0&chartDefaultOnMobile=1&chartTheme=dark&theme=dark&chartStyle=1&chartType=usd&interval=1`}
                  title="Dex Screener"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* Buy/Sell Section */}
        <div className={`col-${isMobile ? '12' : 'xl-4'}`} style={{ paddingLeft: isMobile ? "0px" : "20px", width: isMobile ? "100%" : "30%" }}>
          <div className="card shadow-lg rounded" style={{ background: '#1d1d1d' }}>
            <div className="card-body">
              <Tab.Container defaultActiveKey="Navbuy">
                <div className="buy-sell">
                  <Nav className="nav nav-tabs" eventKey="nav-tab" role="tablist" style={{ borderBottom: '2px solid #444' }}>
                    <Nav.Link as="button" className="nav-link" eventKey="Navbuy" type="button">
                      <FaArrowUp className="me-2" /> Buy
                    </Nav.Link>
                    <Nav.Link as="button" className="nav-link" eventKey="Navsell" type="button">
                      <FaArrowDown className="me-2" /> Sell
                    </Nav.Link>
                  </Nav>
                </div>
                <Tab.Content>
                  {/* Buy Tab */}
                  <Tab.Pane eventKey="Navbuy">
                    <Tab.Container defaultActiveKey="Navbuymarket">
                      <div className="limit-sell">
                        <Nav className="nav nav-tabs" role="tablist">
                          <Nav.Link as="button" eventKey="Navbuymarket" type="button">Market Order</Nav.Link>
                          <Nav.Link as="button" eventKey="Navbuylimit" type="button">Limit Order</Nav.Link>
                        </Nav>
                      </div>
                      <Tab.Content>
                        <Tab.Pane eventKey="Navbuymarket">
                          <div className="form-group mb-3">
                            <label className="form-label text-light">Amount</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter amount"
                              value={buyAmount}
                              onChange={(e) => setBuyAmount(e.target.value)}
                            />
                          </div>

                          <div className="text-center mt-3">
                            <button className="btn btn-success w-75" onClick={handleBuy}>
                              <FaBitcoin className="me-2" /> Buy Now
                            </button>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Tab.Pane>

                  {/* Sell Tab */}
                  <Tab.Pane eventKey="Navsell">
                    <Tab.Container defaultActiveKey="Navsellmarket">
                      <div className="limit-sell">
                        <Nav className="nav nav-tabs">
                          <Nav.Link as="button" eventKey="Navsellmarket" type="button">Market Order</Nav.Link>
                          <Nav.Link as="button" eventKey="Navselllimit" type="button">Limit Order</Nav.Link>
                        </Nav>
                      </div>
                      <Tab.Content>
                        <Tab.Pane eventKey="Navsellmarket">
                          <div className="form-group mb-3">
                            <label className="form-label text-light">Amount</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter amount"
                              value={sellAmount}
                              onChange={(e) => setSellAmount(e.target.value)}
                            />
                          </div>

                          <div className="text-center mt-3">
                            <button className="btn btn-danger w-75" onClick={handleSell}>
                              <FaBitcoin className="me-2" /> Sell Now
                            </button>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>

      {/* Display Wallet Information */}
      <div className="row mt-4">
        {loading ? (
          <div className="text-center text-light">Loading wallet data...</div>
        ) : error ? (
          <div className="text-center text-danger">{error}</div>
        ) : walletData ? (
          <div className="col-12">
            <h5 className="text-light">Wallet Balances</h5>
            <p className="text-light">Solana Balance: {walletData.balance} SOL</p>
            {walletData.tokens && walletData.tokens.length > 0 && (
              <div>
                <h6 className="text-light">Token Balances:</h6>
                {walletData.tokens.map((token, index) => (
                  <p key={index} className="text-light">
                    Token Address: {token.tokenAddress} - Amount: {token.amount}
                  </p>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="text-center text-warning">No wallet data available.</div>
        )}
      </div>
    </div>
  );
};

export default DashboardDark;
