import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

/**
 * Fetches price information for the given MintAddress from the Jupiter Aggregator API.
 * @param {string} mintAddress - The MintAddress to query.
 * @returns {Promise<Object>} - The price data from the API.
 * @throws {Error} - If the API request fails.
 */
const getPriceData = async (mintAddress) => {
    const url = `https://api.jup.ag/price/v2?ids=${mintAddress}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(`Error fetching data from Jupiter Aggregator API: ${error.message}`);
        throw error;
    }
};

const SpotTable = () => {
    const [data, setData] = useState([]); // State to hold data from tokens.json
    const [pageData, setPageData] = useState([]); // Data for the current page
    const [prices, setPrices] = useState({}); // State to store the price data by MintAddress
    const sort = 100; // Number of items per chunk (adjust as needed)
    const activePag = useRef(0); // Track active page
    const [test, setTest] = useState(0); // Trigger re-render for pagination

    // Fetch tokens chunk based on page
    useEffect(() => {
        const chunkNumber = activePag.current + 1; // Calculate the chunk number based on the page
        fetch(`/react/demo/tokens/tokens_chunk_${chunkNumber}.json`) // Adjust path if needed
            .then((response) => response.json())
            .then((jsonData) => {
                setData(jsonData); // Set the chunk data
                setPageData(jsonData.slice(0, sort)); // Set initial page data

                // Fetch price data for each token using mint_address
                jsonData.forEach((token) => {
                    const mintAddress = token.address; // Get mint address from token data
                    getPriceData(mintAddress)
                    .then((priceData) => {
                        console.log(priceData); // Log the price data to see the structure
                        setPrices((prevPrices) => ({
                            ...prevPrices,
                            [mintAddress]: priceData.data.price, // Make sure this key exists
                        }));
                    })
                    .catch((error) => {
                        console.error(`Error fetching price data for ${mintAddress}:`, error);
                    });

                });
            })
            .catch((error) => console.error('Error loading tokens chunk:', error));
    }, [activePag.current]);

    // Pagination function
    const paginateData = (page) => {
        const start = page * sort;
        const end = start + sort;
        setPageData(data.slice(start, end)); // Slice data based on current page
    };

    // Handle page change
    const onClick = (page) => {
        activePag.current = page;
        paginateData(page);
        setTest(page); // Trigger re-render
    };

    // Pagination controls
    const totalPages = Math.ceil(data.length / sort);
    const pagination = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <div className="table-responsive dataTablemarket">
            <div id="spot_wrapper" className="dataTables_wrapper no-footer">
                <table className="table dataTable shadow-hover display" style={{ minWidth: '845px' }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className="text-center">Price</th>
                            <th className="text-center">Change</th>
                            <th className="text-center">24 High/24 Low</th>
                            <th className="text-center">24 Volume</th>
                            <th className="text-center">Market Cap</th>
                            <th className="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pageData.map((item, index) => {
                            const mintAddress = item.address;
                            const price = prices[mintAddress] || 'Loading...'; // Get price or show loading
                            return (
                                <tr key={index}>
                                    <td>
                                        <Link to={`/trade/${item.symbol}USDT`} className="market-title d-flex align-items-center">
                                            <img src={item.logoURI} alt={item.name} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                            <h5 className="mb-0 ms-2">{item.name}</h5>
                                            <span className="text-muted ms-2">{item.symbol}</span>
                                        </Link>
                                    </td>
                                    <td className="text-center">{price}</td> {/* Display price */}
                                    <td className={`${index === 0 || index === 3 ? 'text-danger' : 'text-success'}`}>
                                        {item.daily_volume ? `${item.daily_volume}%` : 'N/A'}
                                    </td>
                                    <td>30,585.00/21,250.00</td> {/* Placeholder for High/Low */}
                                    <td>{item.daily_volume || 'N/A'}</td> {/* Placeholder for volume */}
                                    <td>{'Market Cap'}</td> {/* Placeholder for market cap */}
                                    <td className="text-end">
                                        <Link to={`/trade/${item.symbol}USDT`}>Trade</Link>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info">
                        Showing {activePag.current * sort + 1} to{' '}
                        {data.length > (activePag.current + 1) * sort ? (activePag.current + 1) * sort : data.length}{' '}
                        of {data.length} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers mb-0" id="application-tbl1_paginate">
                        <Link
                            className="paginate_button previous"
                            to="/market"
                            onClick={() => activePag.current > 0 && onClick(activePag.current - 1)}
                        >
                            <i className="fa fa-angle-double-left"></i>
                        </Link>
                        <span>
                            {pagination.map((number, i) => (
                                <Link
                                    key={i}
                                    to="/markets"
                                    className={`paginate_button ${activePag.current === i ? 'current' : ''}`}
                                    onClick={() => onClick(i)}
                                >
                                    {number}
                                </Link>
                            ))}
                        </span>
                        <Link
                            className="paginate_button next"
                            to="/market"
                            onClick={() =>
                                activePag.current + 1 < pagination.length && onClick(activePag.current + 1)
                            }
                        >
                            <i className="fa fa-angle-double-right"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpotTable;
