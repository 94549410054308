import React from "react";

const Home = () => {
  return (
    <div className="homepage">
      {/* Hero Section with Embedded iFrame */}
      <section className="iframe-section" style={{ height: "100vh", overflow: "hidden" }}>
        <iframe
          src="https://broker.chipa.tech"
          title="Broker Platform"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      </section>
    </div>
  );
};

export default Home;
