// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types'; // PropTypes for type checking

function TradingViewWidget({
  width = "980", // Default width if not provided
  height = "610", // Default height if not provided
  symbol = "BINANCE:SOLUSDT", // Default symbol if not provided
  interval = "1", // Default interval if not provided
  timezone = "Etc/UTC", // Default timezone if not provided
  theme = "dark", // Default theme if not provided
  locale = "en", // Default locale if not provided
  allowSymbolChange = true, // Default allow_symbol_change if not provided
  calendar = false, // Default calendar if not provided
  studies = ["STD;DEMA"], // Default studies if not provided
  supportHost = "https://www.tradingview.com" // Default support_host if not provided
}) {
  const container = useRef();

  useEffect(() => {
    // Only load script once on initial mount
    const existingScript = document.getElementById('tradingview-widget-script');
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = 'tradingview-widget-script'; // Set an ID to prevent loading multiple times
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = JSON.stringify({
        width,
        height,
        symbol,
        interval,
        timezone,
        theme,
        locale,
        allow_symbol_change: allowSymbolChange,
        calendar,
        studies,
        support_host: supportHost
      });
      container.current.appendChild(script);
    }
  }, [width, height, symbol, interval, timezone, theme, locale, allowSymbolChange, calendar, studies, supportHost]);

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
}

TradingViewWidget.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  symbol: PropTypes.string,
  interval: PropTypes.string,
  timezone: PropTypes.string,
  theme: PropTypes.string,
  locale: PropTypes.string,
  allowSymbolChange: PropTypes.bool,
  calendar: PropTypes.bool,
  studies: PropTypes.arrayOf(PropTypes.string),
  supportHost: PropTypes.string
};

export default memo(TradingViewWidget);
