import React, { createContext, useState } from "react";

// Create the context
export const NavigationContext = createContext();

// Context provider
const NavigationProvider = ({ children }) => {
  const [navigationHader, setNavigationHader] = useState("defaultValue");

  return (
    <NavigationContext.Provider value={{ navigationHader, setNavigationHader }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
