import React, { useState } from "react";
import { Link } from "react-router-dom";

// Images
import logo from "../../../images/logo/logo-white.png";

// Utility function for toggling menu
export function NavMenuToggle() {
  setTimeout(() => {
    const mainWrapper = document.querySelector("#main-wrapper");
    mainWrapper.classList.toggle("menu-toggle");
  }, 200);
}

// Hamburger SVG component
const HamburgerIcon = ({ isActive }) => (
  <div className={`hamburger ${isActive ? "is-active" : ""}`}>
    <span className="line"></span>
    <span className="line"></span>
    <span className="line"></span>
  </div>
);

const NavHeader = () => {
  const [toggle, setToggle] = useState(false);

  const handleMenuToggle = () => {
    setToggle((prev) => !prev);
    NavMenuToggle();
  };

  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        <img src={logo} alt="Logo" />
      </Link>

      {/* Mobile Hamburger */}
      <div className="nav-control" onClick={handleMenuToggle}>
        <HamburgerIcon isActive={toggle} />
      </div>

      {/* Navigation Links */}
      <div className={`nav-links ${toggle ? "active" : ""}`}>
        <ul>
          <li><Link to="/wallet">Wallet</Link></li>
          <li><Link to="/profile">Profile</Link></li>
          <li><Link to="/settings">Settings</Link></li>
          <li><Link to="/tokens">Tokens</Link></li>
          <li><Link to="/bonk-airdrop">BonkAirdrop</Link></li>
        </ul>
      </div>

      {/* Profile Section (using icon) */}
      <div className="user-profile">
        <Link to="/profile">
          <div className="user-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-user"
              width="24"
              height="24"
            >
              <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"></path>
              <path d="M12 14c-4.41 0-8 1.79-8 4v2h16v-2c0-2.21-3.59-4-8-4z"></path>
            </svg>
          </div>
        </Link>
        <div className="user-dropdown">
          <ul>
            <li><Link to="/profile">My Profile</Link></li>
            <li><Link to="/wallet">My Wallet</Link></li>
            <li><Link to="/settings">Settings</Link></li>
            <li><Link to="/logout">Logout</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
