import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo/logo-full.png';
import bg6 from '../../images/background/bg6.jpg';

function Login() {
    const [email, setEmail] = useState('demo@example.com');
    const [password, setPassword] = useState('123456');
    const [errors, setErrors] = useState({ email: '', password: '' });
    const [apiError, setApiError] = useState('');
    const [apiSuccess, setApiSuccess] = useState('');
    const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = { email: '', password: '' };
        let isValid = true;

        if (!email) {
            newErrors.email = 'Email is required.';
            isValid = false;
        }
        if (!password) {
            newErrors.password = 'Password is required.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const onLogin = async (e) => {
        e.preventDefault();
        setApiError('');
        setApiSuccess('');

        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch('https://api.dex.chipa.tech/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });

            const result = await response.json();

            if (response.ok) {
                setApiSuccess('Login successful!');
				document.cookie = `token=${result.result.uid};`; // Replace 'token' with the appropriate key from your API response
        		document.cookie = `user=${result.result.email};`;
                console.log(result);
                navigate('/trade/SOLUSDT'); // Navigate to dashboard or desired page
            } else {
                setApiError(result?.result || 'Login failed.');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setApiError('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="page-wraper">
            <div className="browse-job login-style3">
                <div className="bg-img-fix overflow-hidden" style={{ background: `#fff url(${bg6})`, height: '100vh' }}>
                    <div className="row gx-0">
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white">
                            <div className="login-form style-2">
                                <div className="card-body">
                                    <div className="logo-header">
                                        <a href="#" className="logo">
                                            <img src={logo} alt="" className="width-230" />
                                        </a>
                                    </div>
                                    <form className="dz-form pb-3" onSubmit={onLogin}>
                                        <h3 className="form-title m-t0">Login</h3>
                                        {apiError && (
                                            <div className="text-danger my-2">{apiError}</div>
                                        )}
                                        {apiSuccess && (
                                            <div className="text-success my-2">{apiSuccess}</div>
                                        )}
                                        <div className="form-group mb-3">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {errors.email && (
                                                <div className="text-danger fs-12">{errors.email}</div>
                                            )}
                                        </div>
                                        <div className="form-group mb-3">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            {errors.password && (
                                                <div className="text-danger fs-12">{errors.password}</div>
                                            )}
                                        </div>
                                        <div className="form-group text-left mb-5">
                                            <button type="submit" className="btn btn-primary dz-xs-flex m-r5">
                                                Login
                                            </button>
                                        </div>
                                    </form>
                                    <div className="text-center bottom">
                                        <a href="/register" className="btn btn-primary button-md btn-block">
                                            Create an account
                                        </a>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="bottom-footer text-center">
                                        <span>
                                            © Copyright by{' '}
                                            <a href="https://www.dexignzone.com/" target="_blank" rel="noopener noreferrer">
                                                DexignZone
                                            </a>{' '}
                                            All rights reserved.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
