import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';  // Import Firestore methods
import axios from 'axios'; // Import Axios for fetching Solana price

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyDr1UFjiNj6miFJtiEueYTFkgnMkadY4dU",
  authDomain: "chipa-86697.firebaseapp.com",
  projectId: "chipa-86697",
  storageBucket: "chipa-86697.appspot.com",
  messagingSenderId: "260355840210",
  appId: "1:260355840210:web:087613fa85259922d1350b",
  measurementId: "G-E2THCMH7SB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);  // Get Firestore instance

const BonkAirdrop = () => {
  const [tradingVolume, setTradingVolume] = useState(0);
  const [tradingVolumeUSD, setTradingVolumeUSD] = useState(0);
  const [chances, setChances] = useState(0);
  const navigate = useNavigate(); // Initialize the navigation hook

  // Get UID from cookie
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    return match ? match[2] : null;
  };
  const uid = getCookie("token");

  // Function to calculate chances based on trading volume
  const calculateChances = (volume) => {
    if (volume >= 1000000) {
      return 300;
    } else if (volume >= 100000) {
      return 30;
    } else if (volume >= 10000) {
      return 5;
    } else if (volume >= 1000) {
      return 1;
    }
    return 0;
  };

  // Fetch the current price of Solana (SOL) in USD using CoinGecko API
  const fetchSolanaPrice = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd');
      const solanaPrice = response.data.solana.usd;
      return solanaPrice;
    } catch (error) {
      console.error('Error fetching Solana price:', error);
      return 0;
    }
  };

  // Fetch user's trading volume from Firestore when the component mounts
  useEffect(() => {
    if (uid) {
      const userRef = doc(db, 'userVolumes', uid);  // Reference to user document in Firestore

      // Get the user's trading volume from Firestore
      getDoc(userRef).then(async (docSnap) => {
        if (docSnap.exists()) {
          const volumeInSolana = docSnap.data().volume || 0;  // Get volume in Solana
          setTradingVolume(volumeInSolana);  // Set the trading volume in Solana

          // Fetch Solana price and convert the volume to USD
          const solanaPrice = await fetchSolanaPrice();
          const volumeInUSD = volumeInSolana * solanaPrice;
          setTradingVolumeUSD(volumeInUSD);  // Set the trading volume in USD

          // Set chances based on the volume in Solana
          setChances(calculateChances(volumeInSolana));
        } else {
          console.log("User not found");
        }
      }).catch((error) => {
        console.error("Error fetching trading volume:", error);
      });
    }
  }, [uid]);  // Only run when uid changes

  // Handle the claim button click event
  const handleClaimButtonClick = () => {
    navigate('/register');  // Redirect to /register when button is clicked
  };

  return (
    <div className="bonk-airdrop-container">
      <div className="bonk-airdrop-paper">
        <h1 className="title">Bonk Airdrop</h1>
        <p className="subtitle">
          Trade to get chances to win the $10,000 Bonk Airdrop!
        </p>

        <p className="instructions">
          To participate, you need to reach certain trading volumes. Here are the chances you'll get based on your volume:
        </p>

        <div className="volume-info">
          <div className="volume-item">1,000 USD - 1 Chance</div>
          <div className="volume-item">10,000 USD - 5 Chances</div>
          <div className="volume-item">100,000 USD - 30 Chances</div>
          <div className="volume-item">1,000,000 USD - 300 Chances</div>
        </div>

        <div className="volume-info">
          <p className="current-volume">Current Trading Volume: {tradingVolume.toLocaleString()} SOL (${tradingVolumeUSD.toLocaleString()})</p>
        </div>

        <div className="chances-display">
          <h3 className="chances-text">Chances to win: {chances}</h3>
        </div>

        <div className="claim-button-container">
          <button className="claim-button" onClick={handleClaimButtonClick}>
            Claim Your Bonk Airdrop
          </button>
        </div>
      </div>
    </div>
  );
};

export default BonkAirdrop;
