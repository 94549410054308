import React, { useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo/logo-full.png";
import bg6 from "../../images/background/bg6.jpg";

function Register() {
  const [heartActive, setHeartActive] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const navigate = useNavigate();

  async function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { email: "", password: "" };

    // Input Validation
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);

    if (error) return;

    // API Request
    try {
      const response = await fetch("https://api.dex.chipa.tech/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (response.ok) {
        // Save data in cookies
        document.cookie = `token=${result.uid}; path=/; max-age=3600; secure; HttpOnly`; // Replace 'token' with the appropriate key from your API response
        document.cookie = `user=${JSON.stringify(result.email)}; path=/; max-age=3600;`;

        setApiMessage({ type: "success", message: "Registration Successful!" });
        navigate("/login"); // Redirect to login page
      } else {
        setApiMessage({ type: "error", message: result.message || "Registration Failed" });
      }
    } catch (err) {
      setApiMessage({ type: "error", message: "An error occurred. Please try again later." });
    }
  }

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{
              background: "#fff url(" + bg6 + ")",
              height: "100vh",
            }}
          >
            <div className="row gx-0">
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white">
                <div
                  id="mCSB_1"
                  className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                  style={{ maxHeight: "653px" }}
                >
                  <div
                    id="mCSB_1_container"
                    className="mCSB_container"
                    style={{ position: "relative", top: "0", left: "0", dir: "ltr" }}
                  >
                    <div className="login-form style-2">
                      <div className="card-body">
                        <div className="logo-header">
                          <Link to="/login" className="logo">
                            <img src={logo} alt="" className="width-230 mCS_img_loaded" />
                          </Link>
                        </div>
                        <nav className="nav nav-tabs border-bottom-0">
                          <div className="tab-content w-100" id="nav-tabContent">
                            <div className="tab-pane active show fade">
                              {apiMessage.message && (
                                <div className={`text-${apiMessage.type === "error" ? "danger" : "success"} mb-3`}>
                                  {apiMessage.message}
                                </div>
                              )}
                              <form className="dz-form py-2" onSubmit={onSignUp}>
                                <h3 className="form-title">Sign Up</h3>
                                <div className="dz-separator-outer m-b5">
                                  <div className="dz-separator bg-primary style-liner"></div>
                                </div>
                                <p>Enter your personal details below: </p>
                                <div className="form-group mt-3">
                                  <input
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control"
                                    placeholder="hello@example.com"
                                  />
                                  {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                </div>
                                <div className="form-group mt-3">
                                  <input
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                    placeholder="Password"
                                  />
                                  {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                </div>
                                <div className="form-group clearfix text-left">
                                  <NavLink to="/login" className="btn btn-primary outline gray" type="button">
                                    Back
                                  </NavLink>
                                  <button type="submit" className="btn btn-primary float-end">
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </nav>
                      </div>
                      <div className="card-footer">
                        <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                          <div className="col-lg-12 text-center">
                            <span>
                              {" "}
                              © Copyright by
                              <span
                                className={`heart ${heartActive ? "" : "heart-blast"}`}
                                onClick={() => setHeartActive(!heartActive)}
                              ></span>
                              <a href="https://www.dexignzone.com/" target="_blank">
                                {" "}
                                DexignZone{" "}
                              </a>{" "}
                              All rights reserved.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
