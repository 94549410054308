import React from "react";
import ReactDOM from "react-dom";
import NavigationProvider from "./jsx/layouts/nav/ContextProvider"; // Adjust path if needed
import WrappedApp from "./App";

ReactDOM.render(
  <NavigationProvider>
    <WrappedApp />
  </NavigationProvider>,
  document.getElementById("root")
);
