import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";

// Components
import Home from "./jsx/pages/home";
import DashboardDark from "./jsx/components/Dashboard/DashboardDark";
import Market from "./jsx/components/Trading/Market";
import Login from "./jsx/pages/Login";
import Register from "./jsx/pages/Registration";
import Wallet from "./jsx/pages/wallet";
import Tokens from "./jsx/pages/Tokens";
import BonkAirdrop from "./jsx/pages/BonkAirdrop";
import NavHader from "./jsx/layouts/nav/NavHader";
import ReactGA from "react-ga4";

// Styles
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

// All Routes
const allroutes = [
  { url: "/", component: <Home /> },
  { url: "/dashboard", component: <Home /> },
  { url: "/trade/:cryptoPair", component: <DashboardDark /> },
  { url: "/market", component: <Market /> },
  { url: "/login", component: <Login /> },
  { url: "/register", component: <Register /> },
  { url: "/wallet", component: <Wallet /> },
  { url: "/tokens", component: <Tokens /> },
  { url: "/bonk-airdrop", component: <BonkAirdrop /> },
];

// Track Page Views
const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views whenever the location changes
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
};

const App = () => {
  const navigate = useNavigate();

  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize("G-XXXXXXXXXX"); // Replace with your GA4 Tracking ID
  }, []);

  // Hook to track page views
  useGoogleAnalytics();

  const handleTradeNow = () => {
    navigate("/market"); // Navigate to the market page
  };

  return (
    <>
      <NavHader />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* Define Routes */}
      <Routes>
        {allroutes.map((route, index) => (
          <Route key={index} path={route.url} element={route.component} />
        ))}
      </Routes>
    </>
  );
};

// Wrap App in Router
const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
